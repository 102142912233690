import React, { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Link, navigate } from "gatsby";

// Context
import { StoreContext } from "../../../components/context/store";

const CUSTOMER_LOGOUT = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`;

const Logout = () => {
  const { setValue, customerAccessToken } = useContext(StoreContext);
  const [customerLogout, { data, loading, error }] = useMutation(
    CUSTOMER_LOGOUT,
    {
      onCompleted: (data) => {
        if (data.customerAccessTokenDelete.userErrors.length) return;
        setValue({
          customerAccessToken: "",
        });
        window.location.reload();
        navigate("/fr/account/login/");
      },
    }
  );

  return (
    <li>
      <button
        onClick={(e) => {
          e.preventDefault();
          customerLogout({
            variables: {
              customerAccessToken: customerAccessToken.accessToken,
            },
          });
        }}
      >
        Logout FR
      </button>
    </li>
  );
};

export default Logout;
